.app-nav-list .MuiListItemButton-root {
  display: block;
  text-align: center;
}

.CUSTOM_WIDTH_STACK {
  border-right: none !important;
}

.css-84fo0t-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}

.MOSTLY-CUSTOMISED-SCROLLBAR {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.MOSTLY-CUSTOMISED-SCROLLBAR::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.MOSTLY-CUSTOMISED-SCROLLBAR::-webkit-scrollbar-thumb {
  background: rgba(99, 115, 129, 0.48);
  border-radius: 5px;
}

*:not(.MOSTLY-CUSTOMISED-SCROLLBAR)::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*:not(.MOSTLY-CUSTOMISED-SCROLLBAR)::-webkit-scrollbar-thumb {
  background: rgba(99, 115, 129, 0.48);
  border-radius: 5px;
}

/* for user view template editor */
.edit-content-off .displayHideForUserEditDms {
  display: none;
}
.edit-content-off .non-editable {
  pointer-events: none;
}
.edit-content-off .mjml-tag {
  outline: none !important;
}

.edit-content-off.editor-active {
  outline: none;
}

.edit-content-off div[style*='right: -2px; bottom: -24px; z-index: 100;'] {
  display: none !important;
}

.MuiButton-root, .MuiTab-root {
  text-transform: none !important;
}

.text-transform-tab-none{
  text-transform:none !important;
}
